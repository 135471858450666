import React from 'react'
import Layout from '../components/layout'

class Wartezeiten extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Wartezeiten | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Wartezeiten</h2>
              </header>
              <div>
                <h3>
                  <span>
                    <strong>
                      Wenn Sie selbst, gute Freunde oder Freundinnen oder Ihr
                      Hausarzt die Frage aufgeworfen haben, ob Ihnen eine
                      Psychotherapie möglicherweise helfen könnte…{' '}
                    </strong>
                  </span>
                </h3>
                <p>
                  <span>
                    …dann sollen diese Informationen Sie ermutigen, Kontakt
                    aufzunehmen und diese Frage gemeinsam mit mir zu besprechen.
                  </span>
                </p>
                <p>
                  <span>
                    <strong>Was kommt auf Sie beim Erstkontakt zu?</strong>
                  </span>
                </p>
                <ul>
                  <li>
                    <span>
                      Ein Erstgespräch mit mir wird in der Regel telefonisch
                      oder per e-mail vereinbart.
                    </span>
                  </li>
                  <li>
                    <span>
                      Ein Psychotherapeutisches Gespräch dauert in der Regel 50
                      Minuten und findet in meinem Praxisraum statt.{' '}
                    </span>
                  </li>
                </ul>
                <p>
                  <span>
                    <strong>
                      Das Erstgespräch und bis zu 3 weitere, so genannte
                      probatorische Sitzungen dienen dazu
                    </strong>
                  </span>
                </p>
                <ul>
                  <li>
                    <span>
                      alle Fragen zu klären, die für die Entscheidung für bzw.
                      auch gegen eine Psychotherapie notwendig sind{' '}
                    </span>
                  </li>
                  <li>
                    <span>eine (vorläufige) Diagnose zu erstellen</span>
                  </li>
                  <li>
                    <span>einen Behandlungsplan zu vereinbaren</span>
                  </li>
                  <li>
                    <span>die Formalitäten der Kostenübernahme zu regeln</span>
                  </li>
                </ul>
                <p>
                  <span>
                    Im Rahmen dieser (genehmigungsfreien) probatorischen
                    Sitzungen wird bei Ihrer Krankenkasse / Privaten
                    Krankenversicherung ein Antrag auf Kostenübernahme gestellt.
                    Während dieser Zeit muss auch ein körperlicher Befund durch
                    einen Haus- oder Facharzt eingeholt werden. Dieser
                    sogenannte Konsiliarbericht dient dazu, dass mögliche
                    körperliche Erkrankungen und deren Einfluss auf die
                    Psychotherapie berücksichtigt werden können.{' '}
                  </span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Wartezeiten
